import styled, { css } from 'styled-components';
import { LoadingShape as RainbowLoadingShape } from 'react-rainbow-components';

export default styled(RainbowLoadingShape).withConfig({
    shouldForwardProp: (prop) => !['width', 'height'].includes(prop),
})<{ width?: string; height?: string; }>`
    display: flex;
    margin: 0.25rem 0rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    
    ${(props) => props.width && css`
        width: ${props.width};
    `};
    
    ${(props) => props.height && css`
        height: ${props.height};
    `};
`;
