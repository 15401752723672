import parsePhoneNumber from 'libphonenumber-js';
import { Field } from '@rainbow-modules/forms';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import {
    StyledButton, ButtonLabel, StyledPhoneInput,
} from './styled';
import useFormValue from '../hooks/useFormValue';

const AskPhoneNumber = () => {
    const phoneNumber = useFormValue({ name: 'phoneNumber' });

    const validatePhoneNumber = (value: any) => {
        if (!value) {
            return 'Please enter a phone number.';
        }
        const parsedPhoneNumber = parsePhoneNumber(
            value.phone,
            value.isoCode.toUpperCase() || 'US',
        );
        if (!parsedPhoneNumber?.isValid()) {
            return 'Please enter a valid phone number.';
        }

        return '';
    };

    return (
        <>
            <PageHeader
                title="Let's stay connected with the patient."
                description="Enter the patient's phone number to send appointment details and reminders."
            />
            <Field
                component={StyledPhoneInput}
                name="phoneNumber"
                label="Patient's Phone Number"
                placeholder="Enter phone number here..."
                borderRadius="semi-square"
                labelAlignment="left"
                countries={['us']}
                validate={validatePhoneNumber}
                required
            />
            <StyledButton
                type="submit"
                borderRadius="semi-square"
                size="large"
                variant="brand"
                shaded
                disabled={!phoneNumber || !phoneNumber.phone}
            >
                <ButtonLabel>
                    Next
                </ButtonLabel>
                <ArrowNarrowRight />
            </StyledButton>
        </>
    );
};

export default AskPhoneNumber;
