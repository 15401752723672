import { DateTime } from 'luxon';

const getFormattedDob = (dob: Date) => {
    const newDob = DateTime.fromObject({
        year: dob.getFullYear(),
        month: dob.getMonth() + 1,
        day: dob.getDate(),
    }, {
        zone: 'utc',
    }).toISO()?.slice(0, 10);

    return newDob || '';
};

export default getFormattedDob;
