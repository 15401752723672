import { ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import {
    ButtonLabel,
    StyledPickerOptionContent,
    StyledOptionLabel,
    StyledUniversalPicker,
    StyledRadioItem,
    StyledRadio,
    StyledButton,
} from './styled';
import useFormValue from '../hooks/useFormValue';

interface RadioItemProps {
    children: ReactNode;
    isHover?: boolean;
    isFocused?: boolean;
    isSelected?: boolean;
    disabled?: boolean;
}

const RadioItem = ({ children, ...rest }: RadioItemProps) => (
    <StyledRadioItem {...rest}>
        <StyledRadio {...rest} />
        <div>{children}</div>
    </StyledRadioItem>
);

const Transportation = () => {
    const { change } = useForm();
    const name = 'transportationRequired';
    const transportation = useFormValue({ name });

    return (
        <>
            <PageHeader
                title="Complimentary Transportation Service 🚗"
                description="Our transportation service is available on a limited basis for patients with no alternatives. Please select 'Yes' only if necessary."
            />
            <StyledUniversalPicker
                multiple={false}
                direction="vertical"
                name={name}
                value={transportation}
                onChange={(value) => change(name, value)}
            >
                <UniversalPickerOption
                    component={RadioItem}
                    name="no"
                >
                    <StyledPickerOptionContent>
                        <StyledOptionLabel>No, transportation is not needed.</StyledOptionLabel>
                    </StyledPickerOptionContent>
                </UniversalPickerOption>
                <UniversalPickerOption
                    component={RadioItem}
                    name="yes"
                >
                    <StyledPickerOptionContent>
                        <StyledOptionLabel>Yes, transportation is needed.</StyledOptionLabel>
                    </StyledPickerOptionContent>
                </UniversalPickerOption>
            </StyledUniversalPicker>
            <StyledButton
                type="submit"
                size="large"
                variant="brand"
                borderRadius="semi-square"
                disabled={!transportation}
            >

                <ButtonLabel>
                    Book Appointment
                </ButtonLabel>
                <ArrowNarrowRight />
            </StyledButton>
            {/* <StyledButton
                    borderRadius="semi-square"
                    size="large"
                    label="Sign Out"
                    onClick={() => auth.signOut()}
                /> */}
        </>
    );
};

export default Transportation;
