import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useHttpQuery from 'data/firestore/useHttpQuery';

interface Response {
    isValidAgent : boolean;
    action: 'next' | 'ask-name';
    displayName: string | null;
}

const CheckUserStatus = () => {
    const navigate = useNavigate();
    const agentId = sessionStorage.getItem('agentId');

    const pathname = agentId ? `/user/status?agentId=${agentId}` : '/user/status';
    const { data } = useHttpQuery<Response>({
        pathname,
    });

    useEffect(() => {
        if (data?.action === 'next') {
            navigate('/app/appointments');
        } else if (data?.action === 'ask-name') {
            navigate('/app/name');
        }
    }, [data, navigate]);

    return null;
};

export default CheckUserStatus;
