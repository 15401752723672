import { useMemo } from 'react';
import { useForm } from 'react-final-form';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import { getFormatter } from 'data/services/date/formatter';
import {
    StyledButton, ButtonLabel, StyledUniversalPicker,
} from './styled';
import RadioItem from './radioItem';
import getSlots from './helpers/getSlots';
import useFormValue from '../hooks/useFormValue';

const AskAppointmentTime = () => {
    const { change } = useForm();
    const date = useFormValue({ name: 'date' });
    const time = useFormValue({ name: 'time' });
    const slots = useMemo(() => (date ? getSlots((date as Date)) : []), [date]);

    return (
        <>
            <PageHeader
                title="What time works best? 🕒"
                description="Pick a time for the patient's appointment on the selected date."
            />
            <StyledUniversalPicker
                multiple={false}
                direction="vertical"
                value={time}
                onChange={(e) => change('time', e)}
            >
                {slots.map((slot) => (
                    <UniversalPickerOption
                        component={RadioItem}
                        name={`${slot.getTime()}`}
                        key={`${slot.getTime()}`}
                    >
                        {getFormatter(
                            'en-US',
                            {
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true,
                            },
                        ).format(slot)}
                    </UniversalPickerOption>
                ))}
            </StyledUniversalPicker>
            <StyledButton
                type="submit"
                borderRadius="semi-square"
                size="large"
                variant="brand"
                disabled={!time}
                shaded
            >
                <ButtonLabel>
                    Confirm Time
                </ButtonLabel>
                <ArrowNarrowRight />
            </StyledButton>
        </>
    );
};

export default AskAppointmentTime;
