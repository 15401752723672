import { Fragment, useMemo } from 'react';
import { RenderIf } from 'react-rainbow-components';
import AppointmentItem from 'components/Appointment';
import { Appointment } from '../../types';
import { Divider } from './styled';

interface Params {
    appointments?: Appointment[];
}

const Appointments = ({
    appointments = [],
}: Params) => useMemo(
    () => appointments
        .map(
            (appointment: Appointment, index: number) => (
                <Fragment key={`appointment__${appointment.id}`}>
                    <AppointmentItem
                        data={appointment}
                        timeZone={appointment.agent.timezone}
                    />
                    <RenderIf isTrue={index < appointments.length - 1}>
                        <Divider />
                    </RenderIf>
                </Fragment>
            ),
        ),
    [appointments],
);

export default Appointments;
