import styled, { css } from 'styled-components';
import { Button } from 'react-rainbow-components';

export default styled(Button)`
    letter-spacing: 0.02688rem;
    font-weight: 400;

    ${(props) => props.size === 'medium' && css`
        font-size: 0.9375rem;
    `}

    ${(props) => props.size === 'large' && css`
        font-size: 1.0625rem;
        font-weight: 500;
        line-height: 3.2rem;
    `}

    ${(props) => props.borderRadius === 'semi-square' && css`
        border-radius: 0.375rem;
    `}

    ${(props) => props.shaded && css`
        box-shadow: 0px 2px 8px 0px rgba(115, 103, 240, 0.30);
    `}
`;
