import { Field, isRequired } from '@rainbow-modules/forms';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import {
    StyledInput, StyledButton, ButtonLabel,
} from './styled';
import useFormValue from '../hooks/useFormValue';

const AskName = () => {
    const firstName = useFormValue({ name: 'firstName' });
    const lastName = useFormValue({ name: 'lastName' });

    return (
        <>
            <PageHeader
                title="Let's Get Started with the Appointment 📅"
                description="We're excited to assist you! Please tell us who the appointment is for."
            />
            <Field
                name="firstName"
                label="Patient's First Name"
                placeholder="Enter first name here..."
                borderRadius="semi-square"
                labelAlignment="left"
                component={StyledInput}
                validate={isRequired()}
                required
            />
            <Field
                name="lastName"
                label="Patient's Last Name"
                placeholder="Enter last name here..."
                borderRadius="semi-square"
                labelAlignment="left"
                component={StyledInput}
                validate={isRequired()}
                required
            />
            <StyledButton
                type="submit"
                borderRadius="semi-square"
                size="large"
                variant="brand"
                shaded
                disabled={!firstName || !lastName}
            >
                <ButtonLabel>
                    Next
                </ButtonLabel>
                <ArrowNarrowRight />
            </StyledButton>
        </>
    );
};

export default AskName;
