import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;
    padding-top: 7.25rem;

    @media (max-width: 800px) {
        height: 100%;
        min-height: 100vh;
    }
`;
