// colors
export const BRAND = '#7367F0';
export const BRAND_LIGHTER = 'rgba(115, 103, 240, 0.16)';
export const BRAND_LIGHT = 'rgba(115, 103, 240, 0.60)';
export const BRAND_BACKGROUND = 'rgba(115, 103, 240, 0.05)';
export const SUCCESS = '#28c76f';
export const SUCCESS_LIGHTER = 'rgba(40, 199, 111, 0.16)';
export const WARNING = '#f8c536';
export const WARNING_LIGHTER = 'rgba(248, 197, 54, 0.16)';
export const ERROR = '#ea5455';
export const ERROR_LIGHTER = 'rgba(234, 84, 85, 0.16)';
export const ORANGE = '#ff9f43';
export const ORANGE_LIGHTER = 'rgba(255, 159, 67, 0.16)';
export const CYAN = '#00CFE8';
export const CYAN_LIGHTER = '#D6F7FB';
export const WHITE = '#fff';
export const DIVIDER = '#DBDADE';
export const GREY = '#B9BBBE';
export const GREY_LIGHTER = '#EFEFEF';

// text colors
export const BLACK = '#4B465C';
export const LIGHTER_GREY = '#A5A2AD';
export const DARKEST_GREY = '#5d596c';
export const DARK_GREY = '#6f6b7d';

// box-shadows
export const CARD_SHADOW = '0px 4px 18px 0px rgba(75, 70, 92, 0.10)';
export const BAR_SHADOW = '0px 2px 4px 0px rgba(165, 163, 174, 0.30)';

// algolia indexes
export const ALGOLIA_INDEX_SESSIONS = 'sessions';
export const ALGOLIA_INDEX_INBOX = 'inbox';
export const ALGOLIA_INDEX_BLOCKED_NUMBER = 'blockednumbers';
export const ALGOLIA_INDEX_CUSTOMERS = 'customers';

// pagination
export const PAGE_SIZE = 10;

// modals
export const ADD_EDIT_CUSTOMER_MODAL = 'add-edit-customer-modal';
export const ADD_EDIT_TEAM_USER = 'add-edit-team-user-modal';
export const EDIT_PHONE_LANGUAGE = 'edit-phone-language-modal';
export const ADD_EDIT_CALENDAR_EVENT = 'add-edit-calendar-event-modal';

// google maps
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
