import { useRef, useState, useEffect } from 'react';

const whenSticky = (selector: string | HTMLElement, callback: (isSticky: boolean) => void) => {
    const element = typeof selector === 'string' ? document.querySelector(selector) : selector;

    if (!element) {
        return null;
    }

    const observer = new IntersectionObserver(
        ([event]) => callback(event.intersectionRatio < 1),
        { threshold: [1], rootMargin: '-1px 0px 0px 0px' },
    );
    observer.observe(element);

    return { observer, element };
};

const useSticky = <T extends HTMLElement>() => {
    const ref = useRef<T>(null);

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        if (!ref.current) {
            return () => {};
        }
        const sticky = whenSticky(ref.current, setIsSticky);
        return () => sticky?.observer.unobserve(sticky?.element);
    }, []);

    return { ref, isSticky };
};

export default useSticky;
