import {
    Routes,
    Route,
    Outlet,
    Navigate,
} from 'react-router-dom';
import { WhenAuthenticated, WhenNoAuthenticated } from '@rainbow-modules/auth';
import usePageViewAnalytics from 'analytics/usePageViewAnalytics';
import Login from './pages/Login';
import CheckUserStatus from './pages/CheckUserStatus';
import EnterName from './pages/EnterName';
import AppointmentConfirmation from './pages/AppointmentConfirmation';
import AppointmentsList from './pages/AppointmentsList';
import EventForm from './pages/EventForm';

const App = () => {
    usePageViewAnalytics();
    return (
        <Routes>
            <Route
                path="/"
                element={(
                    <WhenNoAuthenticated redirect="/app">
                        <Login />
                    </WhenNoAuthenticated>
                )}
            />
            <Route
                path="/app"
                element={(
                    <WhenAuthenticated path="/" redirect="/">
                        <Outlet />
                    </WhenAuthenticated>
                )}
            >
                <Route path="" element={<Navigate to="check" replace />} />
                <Route path="check" element={<CheckUserStatus />} />
                <Route path="name" element={<EnterName />} />
                <Route path="appointments" element={<AppointmentsList />} />
                <Route path="book" element={<EventForm />} />
                <Route path="confirmation" element={<AppointmentConfirmation />} />
            </Route>
            <Route path="*" element={<Navigate to="/app" />} />
        </Routes>
    );
};

export default App;
