import styled from 'styled-components';

export const BadgeContainer = styled.div<{ color: string }>`
    display: inline-flex;
    align-items: center;
    align-self: flex-end;
    color: ${(props) => props.color};
    background: ${(props) => `${props.color}29`};
    text-transform: capitalize;
    
    padding: 0.1875rem 0.375rem;
    border-radius: 0.25rem;
    gap: 0.625rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.875rem;
`;
