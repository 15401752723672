import { createGlobalStyle } from 'styled-components';
import { BAR_SHADOW, WHITE } from './constants';

const GlobalStyle = createGlobalStyle`
    html {
        background: #fff !important;
        height: 100%;
    }

    body {
        font-family: 'Public Sans';
        background-color: #fff;
        height: 100%;

            > div:first-child {
                @media (max-width: 800px) {
                    height: 100%;

                    > div {
                        height: 100%;

                        > div {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    section[role="dialog"][aria-describedby^="modal-content"] {
        padding: 3.5625rem 4.0625rem;

        @media (max-width: 800px) {
            padding: 3rem 1.5rem;
        }

        div[id^="modal-content"] {
            padding: 0;

            div[class*="IconContainer"]:empty {
                margin: 0;
            }
        }

        & > button[data-id="button-icon-element"] {
            display: flex;
            width: 2rem;
            height: 2rem;
            padding: 0.5rem !important;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            top: -0.7rem;
            right: -0.7rem;
            background: ${WHITE};
            border-radius: 0.375rem;
            box-shadow: ${BAR_SHADOW};
        }

        @media (max-width: 800px) {
            & > button[data-id="button-icon-element"] {
                display: flex;
                width: 2rem;
                height: 2rem;
                padding: 0 !important;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                top: 12px;
                right: 12px;
                background: none;
                border-radius: 0;
                box-shadow: none;
        }
    }
        
        & > header {
            padding: 0;
        }

        & > footer {
            border: none;
            padding: 1.5rem 0rem 0rem 1.5rem;
            & > div {
                display: flex;
                justify-content: flex-end;
                align-content: center;
                gap: 1rem;
            }
            button {
                border-radius: 0.375rem;
                margin: 0;
            }
        }
    }
`;

export default GlobalStyle;
