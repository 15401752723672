import { IconProps } from 'types';

const CalendarEvent = ({ className, title }: IconProps) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        fill="none"
    >
        <title>{title}</title>
        <rect
            width={66.667}
            height={66.667}
            x={16.666}
            y={20.833}
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            rx={2}
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M66.667 12.5v16.667M33.333 12.5v16.667M16.666 45.833h66.667M41.666 64.583l6.25 6.25 12.5-12.5"
        />
    </svg>
);
export default CalendarEvent;
