import PageTitle from 'components/PageTitle';
import useFormValue from './hooks/useFormValue';

interface StepTitleProps {
    title?: string;
}

const StepTitle = ({ title }: StepTitleProps) => {
    const value = useFormValue({ name: 'center' });
    return <PageTitle title={title || value?.companyName} />;
};

export default StepTitle;
