import styled from 'styled-components';
import { BLACK } from '../../constants';

const textAlignMap = {
    'flex-start': 'left',
    center: 'center',
    'flex-end': 'right',
};

export const Container = styled.div<{ align: 'flex-start' | 'center' | 'flex-end' }>`
    display: flex;
    width: 100%;
    gap: 0.5rem;
    max-width: 460px;
    height: 3rem;
    box-sizing: border-box;
    align-items: ${({ align }) => align};
    text-align: ${({ align }) => textAlignMap[align]};
`;

export const Title = styled.h1`
    font-size: 1.75rem;
    font-weight: bold;
    color: ${BLACK};
`;
