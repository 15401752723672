import { parsePhoneNumber as parse } from 'libphonenumber-js';
import { PhoneNumber } from '../types';

const parsePhoneNumber = (phoneNumber: PhoneNumber) => {
    const parsedPhoneNumber = parse(
        phoneNumber.phone,
        (phoneNumber.isoCode.toUpperCase() as unknown) || 'US',
    );

    if (!parsedPhoneNumber.isValid()) {
        return '';
    }
    return parsedPhoneNumber.format('E.164');
};

export default parsePhoneNumber;
