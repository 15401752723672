import styled from 'styled-components';

interface Props {
    hideBackButton?: boolean;
}

export const Container = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 1.5rem 0.75rem;
`;

export const Name = styled.span`
    flex-grow: 1;
    text-align: center;
    font-size: 1.75rem;
    font-weight: bold;
`;
