import { CalendarEventStatus } from 'types';
import { BadgeContainer } from './styled';

const labelMap: Record<string, string> = {
    'pending-approval': 'Pending Approval',
    rejected: 'Rejected',
};

interface Params {
    data?: CalendarEventStatus;
}

const EventStatusBadge = ({
    data,
}: Params) => (
    <BadgeContainer color={data?.color as string}>
        {labelMap[data?.name as string] || data?.name}
    </BadgeContainer>
);

export default EventStatusBadge;
