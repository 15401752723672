import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import AppointmentItem from 'components/Appointment';
import PageTitle from 'components/PageTitle';
import TopBar from 'components/TopBar';
import useAppointments from 'data/hooks/useAppointments';
import { Appointment } from 'types';
import {
    Container,
    Header,
    Content,
    Footer,
    StyledButton,
    ButtonLabel,
    EmptyMessage,
} from './styled';
import formatDate from './helpers/formatDate';
import DateSplitter from './splitter';
import Appointments from './appointments';

const AppointmentsList = () => {
    const { data = [], isLoading } = useAppointments<Appointment[]>();
    const navigate = useNavigate();

    const appointments = useMemo(
        () => {
            if (data.length > 0) {
                const groupedAppointments = data.reduce(
                    (
                        acc: { [key: string]: Array<Appointment> },
                        appointment: Appointment,
                    ) => {
                        const date = formatDate(appointment.startAt);
                        if (!Object.keys(acc).includes(date)) {
                            acc[date] = [];
                        }
                        acc[date].push(appointment);
                        return acc;
                    },
                    {},
                );
                return Object.keys(groupedAppointments).map((date) => (
                    <Fragment key={`appointments__${date}`}>
                        <DateSplitter date={date} />
                        <Appointments appointments={groupedAppointments[date]} />
                    </Fragment>
                ));
            }

            return (
                <EmptyMessage>There is no appointments</EmptyMessage>
            );
        },
        [data],
    );

    return (
        <Container>
            <TopBar hideBackButton />
            <Header>
                <PageTitle title="Your Appointments" />
            </Header>
            <Content>
                <RenderIf isTrue={isLoading}>
                    <AppointmentItem isLoading />
                    <AppointmentItem isLoading />
                    <AppointmentItem isLoading />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    {appointments}
                </RenderIf>
            </Content>
            <Footer>
                <StyledButton
                    shaded
                    borderRadius="semi-square"
                    size="large"
                    variant="brand"
                    onClick={() => navigate('/app/book')}
                >
                    <ButtonLabel>New Appointment</ButtonLabel>
                </StyledButton>
            </Footer>
        </Container>
    );
};

export default AppointmentsList;
