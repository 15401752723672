import { useMutation } from 'react-query';
import { auth } from 'data/firestore/firebase';
import { FirebaseError } from 'firebase/app';
import { QueryErrorCode } from 'data/firestore/types';

const errorCodes: Record<number, QueryErrorCode> = {
    400: 'bad-request',
    401: 'unauthorized',
    403: 'forbidden',
    404: 'not-found',
    405: 'method-not-allowed',
    406: 'not-acceptable',
    409: 'conflict',
    500: 'internal-server-error',
    501: 'not-implemented',
    502: 'bad-gateway',
    503: 'service-unavailable',
    504: 'gateway-timeout',
};

export const getQueryErrorCode = (status: number): QueryErrorCode => errorCodes[status] || 'unknown';

export class QueryError extends FirebaseError {
    /**
     * The backend error code associated with this error.
     */
    readonly code: QueryErrorCode;

    constructor(code: QueryErrorCode, message?: string) {
        super(code, message || '');
        this.code = code;

        // Set the prototype explicitly. See https://www.typescriptlang.org/docs/handbook/2/classes.html#inheriting-built-in-types
        Object.setPrototypeOf(this, QueryError.prototype);
    }
}

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface UseMutationParams<Res> {
    pathname?: string;
    method?: Method;
    server?: string;
    onSuccess?: (data: Res) => void;
    onError?: (error: Error) => void;
}

export interface MutationParams<Req, Res>
    extends Pick<UseMutationParams<Res>, 'pathname'> {
    body: Req;
}

const createRequestHeaders = async () => {
    const headers: Record<string, string> = {};
    if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${idToken}`;
    }
    return headers;
};

const useMultipartMutation = <FormData, Res>(params: UseMutationParams<Res>) => {
    const {
        pathname: hookPathname,
        method = 'POST',
        server = process.env.REACT_APP_BASE_URL,
        onSuccess = () => {},
        onError = () => {},
    } = params;
    return useMutation<Res, QueryError, MutationParams<FormData, Res>, unknown>(
        async (mParams: MutationParams<FormData, Res>): Promise<Res> => {
            const { body, pathname: mutationPathname } = mParams;
            const pathname = mutationPathname || hookPathname;
            if (!pathname) {
                throw new Error('No pathname provided');
            }
            const url = new URL(pathname, server).toString();
            const response = await fetch(url, {
                method,
                headers: await createRequestHeaders(),
                body: body as BodyInit,
            });
            const contentType = response.headers.get('Content-Type');
            if (response.ok) {
                if (contentType?.includes('application/json')) return response.json() as Promise<Res>;
                return response.text() as Promise<Res>;
            }
            if (contentType?.includes('text/html')) {
                const result = await response.text();
                throw new QueryError(getQueryErrorCode(response.status), result);
            }
            const result = await response.json();
            throw result;
        },
        {
            onSuccess,
            onError,
        },
    );
};

export default useMultipartMutation;
