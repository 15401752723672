import { ReactNode } from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    ElementContainer, IconContainer, StyledValue,
} from './styled';

interface DetailElementProps {
    icon?: ReactNode;
    value?: ReactNode | string;
}

const DetailElement = ({
    icon,
    value,
}: DetailElementProps) => (
    <ElementContainer>
        <RenderIf isTrue={icon}>
            <IconContainer>
                {icon}
            </IconContainer>
        </RenderIf>
        <StyledValue>{value}</StyledValue>
    </ElementContainer>
);

export default DetailElement;
