import { StoredFormValues } from '../types';

const parseStoredValues = (): Record<string, unknown> => {
    const { file, ...storedValues } = JSON.parse(sessionStorage.getItem('eventForm') || '{}') as StoredFormValues;
    const parsedValues = {
        ...storedValues,
        dob: storedValues.dob ? new Date(storedValues.dob) : undefined,
        date: storedValues.date ? new Date(storedValues.date) : undefined,
    };

    return parsedValues;
};

export default parseStoredValues;
