import { ReactNode } from 'react';
import { Container, Title } from './styled';

interface SummaryItemProps {
    title: ReactNode | string;
    align?: 'flex-start' | 'center' | 'flex-end';
    className?: string;
}

const PageTitle = ({
    title, align = 'flex-start', className,
}: SummaryItemProps) => (
    <Container className={className} align={align}>
        <Title>
            {title}
        </Title>
    </Container>
);

export default PageTitle;
