import { DateTime } from 'luxon';

function formatDate(
    dateStr?: string,
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
) {
    if (!dateStr) return '';
    const today = DateTime.now().setZone(timezone);
    const date = DateTime.fromISO(dateStr).setZone(timezone);

    if (date.hasSame(today, 'year')) return date.toFormat('EEE, LLLL d');

    return date.toFormat('EEE, MMMM d, YYYY');
}

export default formatDate;
