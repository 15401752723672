import { Field, isRequired } from '@rainbow-modules/forms';
import { useNavigate } from 'react-router-dom';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import useHttpMutation from 'data/firestore/useHttpMutation';
import { StyledUniversalForm } from '../EventForm/styled';
import { Container } from './styled';
import {
    StyledInput, StyledButton, ButtonLabel,
} from '../EventForm/name/styled';

interface Value {
    displayName: string;
}

const EnterName = () => {
    const navigate = useNavigate();
    const agentId = sessionStorage.getItem('agentId');

    const {
        mutateAsync: createAffiliate,
        isLoading,
    } = useHttpMutation<Value, { success: boolean }>({
        pathname: `agents/${agentId}/affiliates`,
        method: 'POST',
    });

    const submit = async ({ displayName }: Value) => {
        try {
            await createAffiliate({
                body: { displayName },
            });
        } catch (error) {
            //
        }
        return navigate('/app/appointments');
    };

    return (
        <Container>
            <StyledUniversalForm
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onSubmit={submit}
            >
                <PageHeader
                    title="To begin, what's your name?"
                    description="Please tell us your name to personalize your experience."
                />
                <Field
                    name="displayName"
                    label="Enter your name"
                    placeholder="Enter first name here..."
                    borderRadius="semi-square"
                    labelAlignment="left"
                    component={StyledInput}
                    validate={isRequired()}
                    required
                />
                <StyledButton
                    type="submit"
                    borderRadius="semi-square"
                    size="large"
                    variant="brand"
                    shaded
                    isLoading={isLoading}
                >
                    <ButtonLabel>
                        Next
                    </ButtonLabel>
                    <ArrowNarrowRight />
                </StyledButton>
            </StyledUniversalForm>
        </Container>
    );
};

export default EnterName;
