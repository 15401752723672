import styled from 'styled-components';
import { BLACK, DARKEST_GREY, LIGHTER_GREY } from '../../constants';

export const Container = styled.div`
    display: flex;
    padding: 0.6875rem 0.75rem 1rem 0rem;
    align-items: flex-start;
    align-self: stretch;
`;

export const AvatarContainer = styled.div`
    flex: 0;
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 0.75rem;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex: 1;
    align-self: stretch;
    justify-content: space-between;
    gap: 1rem;
`;

export const Column = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.125rem;

    
    &:first-child {
        flex: 1;
    }
`;

export const Label = styled.h3`
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem;
    color: ${BLACK};
`;

export const DateLabel = styled.div`
    align-self: flex-end;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: ${LIGHTER_GREY};
`;

export const ElementContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    color: ${DARKEST_GREY};
    margin: 0;
    font-size: 0.75rem;
`;

export const IconContainer = styled.div`
    display: inline-flex;
`;

export const StyledValue = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: ${BLACK};
`;
