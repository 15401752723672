import { useCallback, useLayoutEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import TopBar from 'components/TopBar';
import LegalFooter from 'components/LegalFooter';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import useCreateEvent from 'hooks/useCreateEvent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, StyledUniversalForm } from './styled';
import PickAgent from './pickAgent';
import AskName from './name';
import AskPhoneNumber from './phoneNumber';
import AskDateOfBirth from './dob';
import AskInsuranceCard from './insurance';
import AskAppointmentDate from './date';
import AskAppointmentTime from './time';
import getFormattedDob from './helpers/getFormattedDob';
import { FormValues } from './types';
import parsePhoneNumber from './helpers/parsePhoneNumber';
import parseStoredValues from './helpers/parseStoredValues';
import Transportation from './transportation';
import StepTitle from './title';

const stepsMap: Record<string, any> = {
    '': {
        title: 'New Appointment',
        component: PickAgent,
    },
    name: {
        title: 'Name',
        component: AskName,
    },
    phoneNumber: {
        title: 'Phone Number',
        component: AskPhoneNumber,
    },
    dob: {
        title: 'Date of Birth',
        component: AskDateOfBirth,
    },
    insurance: {
        title: 'Insurance',
        component: AskInsuranceCard,
    },
    transportation: {
        title: 'Transportation',
        component: Transportation,
    },
    date: {
        title: 'Appointment Date',
        component: AskAppointmentDate,
    },
    time: {
        title: 'Appointment Time',
        component: AskAppointmentTime,
    },
};
const stepNames = Object.keys(stepsMap);

const isLastStep = (name: string | null) => name === stepNames[stepNames.length - 1];

const isFirstStep = (name: string | null) => !name || name === stepNames[0];

const EventForm = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const step = searchParams.get('step') || '';
    const { mutateAsync: createEvent } = useCreateEvent();
    const initialValues = useRef(parseStoredValues());

    // If we enter the form in any step, and there is no stored data
    // then we start from the beginning.
    useLayoutEffect(() => {
        if (!step) return;

        const storedData = sessionStorage.getItem('eventForm');
        if (!storedData) {
            navigate('/app/book', {
                replace: true,
            });
        }
    }, [navigate, step]);

    const validate = useCallback(
        (values: Partial<FormValues>) => {
            let msg = '';
            let goToStep = null;

            if (!values.center) {
                msg = 'Medical Center is required';
                goToStep = '';
            } else if (!values.firstName || !values.lastName) {
                msg = 'Name is required';
                goToStep = 'name';
            } else if (!values.phoneNumber) {
                msg = 'Phone number is required';
                goToStep = 'phoneNumber';
            } else if (!values.dob) {
                msg = 'Date of birth is required';
                goToStep = 'dob';
            } else if (!values.file) {
                msg = 'Insurance card is required';
                goToStep = 'insurance';
            } else if (!values.date) {
                msg = 'Date is required';
                goToStep = 'date';
            } else if (!values.time) {
                msg = 'Time is required';
                goToStep = 'time';
            }

            if (msg) {
                showAppNotification({
                    title: 'Please fill the required fields',
                    description: msg,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            if (goToStep) {
                setSearchParams({ step: goToStep });
            }

            return !msg && !goToStep;
        },
        [setSearchParams],
    );

    const onComplete = useCallback(
        async (values: FormValues) => {
            const valid = validate(values);
            if (!valid) return;
            const {
                agentId,
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
                locationId,
            } = values.center || {};
            const {
                firstName, lastName, phoneNumber, dob, file, time, transportationRequired,
            } = values;

            const fileItem = file && file[0];
            const startAt = DateTime
                .fromMillis(Number(time))
                .setZone(timeZone, { keepLocalTime: true })
                .toJSDate()
                .toISOString();

            const data = new FormData();
            data.append('file', fileItem);
            data.append('agentId', agentId);
            if (locationId) {
                data.append('locationId', locationId);
            }
            data.append('firstName', String(firstName));
            data.append('lastName', lastName);
            data.append('phoneNumber', parsePhoneNumber(phoneNumber));
            data.append('dob', getFormattedDob(dob));
            data.append('startAt', startAt);
            data.append('transportationRequired', transportationRequired === 'yes' ? 'true' : 'false');

            showAppSpinner();
            try {
                await createEvent({
                    body: data,
                });
                sessionStorage.removeItem('eventForm');
                hideAppSpinner();
                navigate('/app/confirmation', {
                    replace: true,
                });
            } catch (error) {
                showAppNotification({
                    title: 'Something went wrong',
                    description: (error as Error).message || 'Please try again later.',
                    icon: 'error',
                    timeout: 5000,
                });
                hideAppSpinner();
            }
        },
        [createEvent, navigate, validate],
    );

    const prev = useCallback(
        () => {
            if (!isFirstStep(step)) {
                const prevStep = stepNames[stepNames.indexOf(step) - 1];
                setSearchParams({ step: prevStep });
            } else {
                navigate('/app/appointments', { replace: true });
            }
        },
        [navigate, setSearchParams, step],
    );

    const next = useCallback(
        (values: Record<string, unknown>) => {
            if (isLastStep(step)) {
                onComplete(values as FormValues);
            } else {
                sessionStorage.setItem('eventForm', JSON.stringify(values));
                const nextStep = stepNames[stepNames.indexOf(step) + 1];
                setSearchParams({ step: nextStep });
            }
        },
        [onComplete, setSearchParams, step],
    );

    const { component: Component, title } = stepsMap[step] || stepsMap[stepNames[0]];
    const stepTitle = !step ? title : undefined;
    return (
        <Container>
            <TopBar onBack={prev} />
            <StyledUniversalForm
                initialValues={initialValues.current}
                onSubmit={next}
            >
                <StepTitle title={stepTitle} />
                <Component />
            </StyledUniversalForm>
            <LegalFooter />
        </Container>
    );
};

export default EventForm;
