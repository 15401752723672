import { UniversalForm } from '@rainbow-modules/forms';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;

    @media (max-width: 800px) {
        height: 100%;
        min-height: 100vh;
    }
`;

export const StyledUniversalForm = styled(UniversalForm)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
    width: 100%;
    padding: 0 2rem 2.5rem 2rem;
    gap: 24px;
    flex-grow: 1;
`;
