import { StyledFooter, StyledLink } from './styled';

const LegalFooter = () => {
    const currentYear = new Date().getFullYear();

    return (
        <StyledFooter>
            <div>
                ©
                {' '}
                {currentYear}
                {' '}
                With ❤️ by
                <StyledLink href="https://www.voxity.ai/" target="_blank" rel="noreferrer">
                    Voxity
                </StyledLink>
            </div>
            <div>
                <StyledLink href="https://www.voxity.ai/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy
                </StyledLink>
                <span> | </span>
                <StyledLink href="https://www.voxity.ai/terms-of-service" target="_blank" rel="noreferrer">
                    Terms
                </StyledLink>
            </div>
        </StyledFooter>
    );
};

export default LegalFooter;
