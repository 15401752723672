import styled from 'styled-components';
import { BLACK } from '../../constants';

const textAlignMap = {
    'flex-start': 'left',
    center: 'center',
    'flex-end': 'right',
};
export const Container = styled.div<{ align: 'flex-start' | 'center' | 'flex-end' }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    align-items: ${({ align }) => align};
    text-align: ${({ align }) => textAlignMap[align]};
`;

export const Title = styled.h1`
    font-size: 1.625rem;
    font-style: normal;
    color: ${BLACK};
`;

export const Description = styled.p`
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${BLACK};
`;
