import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { Phone as PhoneIcon } from '@rainbow-modules/icons';
import { Appointment } from 'types';
import EventStatusBadge from 'components/EventStatusBadge';
import LoadingShape from 'components/LoadingShape';
import UserAvatar from 'components/UserAvatar';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getInitials from 'data/services/string/getInitials';
import {
    Container,
    AvatarContainer,
    DetailsContainer,
    Column,
    Label,
    DateLabel,
} from './styled';
import DetailElement from './detail';

interface Params {
    isLoading?: boolean;
    timeZone?: string;
    data?: Appointment;
}

export const AppointmentItem = ({
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    isLoading = false,
    data,
}: Params) => {
    const {
        agent, people, status, startAt, duration,
    } = data || {};

    const formattedEventTime = useMemo(
        () => {
            if (isLoading || !startAt) return null;
            const from = DateTime.fromISO(startAt).setZone(timeZone);
            const to = DateTime.fromISO(startAt).setZone(timeZone).plus({ seconds: duration });

            const fromA = (
                from.toFormat('a') === to.toFormat('a')
                    ? ''
                    : ` ${from.toFormat('a')}`
            );

            return `${from.toFormat('h:mm')}${fromA} - ${to.toFormat('h:mm a')}`;
        },
        [duration, startAt, timeZone, isLoading],
    );

    const formattedPhoneNumber = useMemo(
        () => {
            if (isLoading || !people?.phoneNumber) return null;
            return formatIntlPhoneNumber(people.phoneNumber);
        },
        [people, isLoading],
    );

    const initials = useMemo(
        () => {
            if (isLoading || !people?.displayName) return null;
            if (people.displayName === 'Unknown') return null;
            return getInitials(people.displayName);
        },
        [people, isLoading],
    );

    if (isLoading) {
        return (
            <Container>
                <AvatarContainer>
                    <LoadingShape shape="circle" />
                </AvatarContainer>
                <DetailsContainer>
                    <Column>
                        <LoadingShape height="1rem" />
                        <LoadingShape width="50%" height="10px" />
                        <LoadingShape width="60%" height="10px" />
                    </Column>
                    <Column>
                        <LoadingShape width="30%" height="10px" />
                        <LoadingShape width="30%" height="10px" />
                    </Column>
                </DetailsContainer>
            </Container>
        );
    }

    return (
        <Container>
            <AvatarContainer>
                <UserAvatar
                    value={people?.id}
                    initials={initials}
                />
            </AvatarContainer>
            <DetailsContainer>
                <Column>
                    <Label>{people?.displayName}</Label>
                    <DetailElement icon={<PhoneIcon />} value={formattedPhoneNumber} />
                    <DetailElement icon="🏥" value={agent?.companyName} />
                </Column>
                <Column>
                    <DateLabel>{formattedEventTime}</DateLabel>
                    <EventStatusBadge data={status} />
                </Column>
            </DetailsContainer>
        </Container>
    );
};

export default AppointmentItem;
