import styled from 'styled-components';
import Input from 'components/Input';
import Button from 'components/Button';

export const StyledInput = styled(Input)`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin-bottom: 1.5rem;

    &[disabled] {
        box-shadow: none; !important;
    }
`;

export const ButtonLabel = styled.span`
    width: 100%;
    padding-left: 1.4rem;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
