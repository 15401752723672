export default function getSlots(date: Date) {
    const slots = [];
    for (let i = 8; i < 17; i += 1) {
        for (let j = 0; j < 60; j += 15) {
            const slot = new Date(date.getTime());
            slot.setHours(i, j, 0, 0);
            slots.push(slot);
        }
    }
    return slots;
}
