import { ReactNode } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { Container, Title, Description } from './styled';

interface SummaryItemProps {
    title: ReactNode | string;
    description?: ReactNode | string;
    align?: 'flex-start' | 'center' | 'flex-end';
    className?: string;
}

const PageHeader = ({
    title, description, className, align = 'flex-start',
}: SummaryItemProps) => (
    <Container align={align} className={className}>
        <Title>
            {title}
        </Title>
        <RenderIf isTrue={Boolean(description)}>
            <Description>
                {description}
            </Description>
        </RenderIf>
    </Container>
);

export default PageHeader;
