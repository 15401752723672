import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TopBar from 'components/TopBar';
import LegalFooter from 'components/LegalFooter';
import CalendarEvent from 'components/Icons/calendarEvent';
import PageHeader from 'components/PageHeader';
import {
    Container, FormContainer, StyledButton,
    StyledIconContainer, Content, ButtonsContainer,
} from './styled';

const AppointmentConfirmation = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const navigateWReplace = useCallback(
        (url: string) => navigate(url, { replace: true }),
        [navigate],
    );

    const companyName = state?.center?.companyName || ' ';
    return (
        <Container>
            <TopBar title={companyName} hideBackButton />
            <FormContainer>
                <Content>
                    <StyledIconContainer>
                        <CalendarEvent />
                    </StyledIconContainer>
                    <PageHeader
                        title="Appointment Confirmed 🎉"
                        description="Everything&apos;s set! The appointment details have been secured."
                        align="center"
                    />
                </Content>
                <ButtonsContainer>
                    <StyledButton
                        shaded
                        borderRadius="semi-square"
                        size="large"
                        variant="brand"
                        label="Book Another Appointment"
                        onClick={() => navigateWReplace('/app/book')}
                    >
                        Book Another Appointment
                    </StyledButton>

                    <StyledButton
                        borderRadius="semi-square"
                        size="large"
                        variant="outline-brand"
                        label="Go to Main Page"
                        onClick={() => navigateWReplace('/app/appointments')}
                    />
                </ButtonsContainer>
            </FormContainer>
            <LegalFooter />
        </Container>
    );
};

export default AppointmentConfirmation;
