import { useFormState } from 'react-final-form';

interface Props {
    name: string;
}

const useFormValue = ({ name }: Props) => {
    const { values } = useFormState({
        subscription: {
            values: true,
        },
    });
    const value = values[name];

    return value;
};

export default useFormValue;
