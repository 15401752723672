import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import { AvatarMenu, MenuItem, RenderIf } from 'react-rainbow-components';
import { usePostHog } from 'posthog-js/react';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { auth } from 'data/firestore/firebase';
import ButtonIcon from 'components/ButtonIcon';
import ChevronLeft from 'components/Icons/chevronLeft';
import getInitials from 'data/services/string/getInitials';
import UserIcon from 'components/Icons/user';
import Logout from 'components/Icons/logout';
import { analytics } from '../../firebase';
import { Container, Name } from './styled';

interface Props {
    title?: React.ReactNode;
    onBack?: () => void;
    hideBackButton?: boolean;
}

const TopBar = ({ title, onBack, hideBackButton = false }: Props) => {
    const user = useCurrentUser();
    const {
        displayName,
        photoURL,
    } = user || {};
    const posthog = usePostHog();

    const handleLogout = () => {
        auth.signOut();
        posthog?.reset();
        setUserId(analytics, null);
        setUserProperties(analytics, {
            name: null,
            email: null,
        });
    };

    return (
        <Container hideBackButton={hideBackButton}>
            <RenderIf isTrue={!hideBackButton}>
                <ButtonIcon
                    size="small"
                    borderRadius="semi-square"
                    icon={<ChevronLeft />}
                    onClick={onBack}
                />
            </RenderIf>
            <Name>
                <RenderIf isTrue={title}>
                    {title}
                </RenderIf>
            </Name>
            <RenderIf isTrue={user}>
                <AvatarMenu
                    icon={<UserIcon />}
                    initials={getInitials(displayName as string)}
                    src={photoURL}
                >
                    <MenuItem label="Logout" icon={<Logout />} onClick={handleLogout} />
                </AvatarMenu>
            </RenderIf>
        </Container>
    );
};

export default TopBar;
