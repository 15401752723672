import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from 'react-rainbow-components';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import logEvent from 'analytics/ga';
import { app } from './firebase';
import GlobalStyles from './globalStyles';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';

const { REACT_APP_PUBLIC_POSTHOG_KEY = '', REACT_APP_PUBLIC_POSTHOG_HOST = '' } = process.env;

const isProduction = REACT_APP_PUBLIC_POSTHOG_KEY && REACT_APP_PUBLIC_POSTHOG_HOST;

if (isProduction) {
    posthog.init(
        REACT_APP_PUBLIC_POSTHOG_KEY,
        {
            api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
        },
    );
}

const logError = (error: Error) => {
    const params = {
        message: error.toString(),
        stack: error?.stack,
    };
    posthog?.capture('$exception', params);
    logEvent('exception', params);
};

const QueryParamsHandler = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const agentId = searchParams.get('agentId') || '';
        sessionStorage.setItem('agentId', agentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <PostHogProvider client={posthog}>
            <RainbowFirebaseApp
                app={app}
                theme={theme}
                onError={logError}
                spinner={(
                    <Spinner
                        variant="brand"
                        type="arc"
                        size="large"
                    />
                )}
            >
                <GlobalStyles />
                <QueryParamsHandler />
                <App />
            </RainbowFirebaseApp>
        </PostHogProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
