import { ReactNode, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { LoadingShape, RenderIf } from 'react-rainbow-components';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import useAvailableAgents from 'data/hooks/useAvailableAgents';
import PageHeader from 'components/PageHeader';
import {
    ButtonLabel,
    StyledPickerOptionContent,
    StyledOptionLabel,
    StyledOptionDescription,
    StyledUniversalPicker,
    StyledRadioItem,
    StyledRadio,
    StyledContent,
    StyledSpinner,
    StyledButton,
} from './styled';
import useFormValue from '../hooks/useFormValue';

interface RadioItemProps {
    children: ReactNode;
    isHover?: boolean;
    isFocused?: boolean;
    isSelected?: boolean;
    disabled?: boolean;
}

const RadioItem = ({ children, ...rest }: RadioItemProps) => (
    <StyledRadioItem {...rest}>
        <StyledRadio {...rest} />
        <div>{children}</div>
    </StyledRadioItem>
);

type Agent = {
    id: string;
    locationName?: string;
    companyName: string;
    companyAddress?: string;
};

const PickAgent = () => {
    const { change } = useForm();
    const center = useFormValue({ name: 'center' });
    const { data, isLoading } = useAvailableAgents<Agent[]>();
    // const { data: events } = useHttpQuery({
    //     pathname: '/appointments',
    //     method: 'GET',
    // });
    // console.log(events);

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0) {
            if (!center && data.length === 1) change('center', data[0]);
        }
    }, [data, center, change]);

    return (
        <>
            <PageHeader
                title="Choose Your Preferred Medical Center 🏥"
                description="Select the medical center where you'd like to book the appointment."
            />
            <RenderIf isTrue={isLoading}>
                <RadioItem>
                    <StyledPickerOptionContent>
                        <StyledOptionLabel>
                            <LoadingShape />
                        </StyledOptionLabel>
                        <StyledOptionDescription>
                            <LoadingShape />
                        </StyledOptionDescription>
                    </StyledPickerOptionContent>
                </RadioItem>
            </RenderIf>
            <StyledUniversalPicker
                multiple={false}
                direction="vertical"
                value={center?.id}
                onChange={(v: any) => {
                    change('center', data?.find((d) => d.id === v) || null);
                }}
            >
                {data?.map(({
                    id, locationName, companyName, companyAddress,
                }) => {
                    const name = locationName ? `${companyName} - ${locationName}` : companyName;
                    return (
                        <UniversalPickerOption
                            component={RadioItem}
                            name={id}
                            key={id}
                        >
                            <StyledPickerOptionContent>
                                <StyledOptionLabel>{name}</StyledOptionLabel>
                                <StyledOptionDescription>{companyAddress}</StyledOptionDescription>
                            </StyledPickerOptionContent>
                        </UniversalPickerOption>
                    );
                })}
            </StyledUniversalPicker>
            <StyledButton
                type="submit"
                size="large"
                variant="brand"
                borderRadius="semi-square"
                disabled={isLoading || !center}
            >
                <RenderIf isTrue={isLoading}>
                    <StyledContent>
                        <ButtonLabel>
                            Book Appointment
                        </ButtonLabel>
                        <ArrowNarrowRight />
                        <StyledSpinner
                            isVisible={isLoading}
                            variant="inverse"
                            size="small"
                        />
                    </StyledContent>
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <ButtonLabel>
                        Book Appointment
                    </ButtonLabel>
                    <ArrowNarrowRight />
                </RenderIf>
            </StyledButton>
            {/* <StyledButton
                    borderRadius="semi-square"
                    size="large"
                    label="Sign Out"
                    onClick={() => auth.signOut()}
                /> */}
        </>
    );
};

export default PickAgent;
