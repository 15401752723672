import { Avatar } from 'react-rainbow-components';
import styled from 'styled-components';
import UserIcon from 'components/Icons/user';
import {
    BRAND,
    BRAND_LIGHTER,
    SUCCESS,
    SUCCESS_LIGHTER,
    ERROR,
    ERROR_LIGHTER,
    ORANGE,
    ORANGE_LIGHTER,
    CYAN,
    CYAN_LIGHTER,
    GREY,
    GREY_LIGHTER,
} from '../../constants';

const backgroundColorMap = [
    BRAND_LIGHTER,
    SUCCESS_LIGHTER,
    ERROR_LIGHTER,
    ORANGE_LIGHTER,
    CYAN_LIGHTER,
];

const iconColorMap = [
    BRAND,
    SUCCESS,
    ERROR,
    ORANGE,
    CYAN,
];

const getColorIndexForValue = (value:string) => value.toLowerCase().split('').reduce(
    (acc, letter) => {
        const charNumber = Number(letter) || String(letter).charCodeAt(0);
        return acc + charNumber;
    },
    0,
) % iconColorMap.length;

const getBackgroundColorForValue = (value:string, role?: string): string => {
    if (value === 'assistant' || role === 'agent') return GREY_LIGHTER;

    if (role === 'viewer') return ORANGE_LIGHTER;

    if (role === 'editor') return CYAN_LIGHTER;

    if (role === 'owner') return GREY_LIGHTER;

    return backgroundColorMap[getColorIndexForValue(value)];
};

const getIconColorForValue = (value:string, role?: string): string => {
    if (value === 'assistant' || role === 'agent') return GREY;

    if (role === 'viewer') return ORANGE;

    if (role === 'editor') return CYAN;

    if (role === 'owner') return GREY;

    return iconColorMap[getColorIndexForValue(value)];
};

const StyledAvatar = styled(Avatar).withConfig({
    shouldForwardProp: (prop) => !['value', 'role'].includes(prop),
})<{ value: string; role?:string }>`
    flex-shrink: 0;
    color: ${(props) => getIconColorForValue(props.value, props.role)};
    background: ${(props) => getBackgroundColorForValue(props.value, props.role)};
    :hover {
        color: ${(props) => getIconColorForValue(props.value, props.role)};
    }
`;

const UserAvatar = ({
    icon = <UserIcon />,
    value = '',
    ...rest
}: any) => <StyledAvatar {...rest} value={value} icon={icon} />;

export default UserAvatar;
