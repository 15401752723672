import { useForm } from 'react-final-form';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import {
    StyledButton, ButtonLabel, StyledCalendar,
} from './styled';
import useFormValue from '../hooks/useFormValue';

const AskAppointmentDate = () => {
    const { change } = useForm();
    const fieldName = 'date';
    const date = useFormValue({ name: fieldName });

    return (
        <>
            <PageHeader
                title="When would you like to schedule the appointment?"
                description="Select a date that's convenient for the patient."
            />
            <StyledCalendar
                minDate={new Date()}
                value={date}
                onChange={(e) => change(fieldName, e)}
            />
            <StyledButton
                type="submit"
                borderRadius="semi-square"
                size="large"
                variant="brand"
                disabled={!date}
                shaded
            >
                <ButtonLabel>
                    Choose Time Slot
                </ButtonLabel>
                <ArrowNarrowRight />
            </StyledButton>
        </>
    );
};

export default AskAppointmentDate;
