import { Field, isRequired } from '@rainbow-modules/forms';
import ArrowNarrowRight from 'components/Icons/arrowNarrowRight';
import PageHeader from 'components/PageHeader';
import {
    StyledButton, ButtonLabel, StyledDatePicker,
} from './styled';
import useFormValue from '../hooks/useFormValue';

const AskDateOfBirth = () => {
    const dob = useFormValue({ name: 'dob' });

    return (
        <>
            <PageHeader
                title="We need a bit more info to ensure top-notch care."
                description="Please provide the patient's date of birth."
            />
            <Field
                component={StyledDatePicker}
                name="dob"
                label="Patient's Date of Birth"
                placeholder="MM/DD/YYYY"
                borderRadius="semi-square"
                labelAlignment="left"
                validate={isRequired()}
                maxDate={new Date()}
                locale="en-US"
                required
            />
            <StyledButton
                type="submit"
                borderRadius="semi-square"
                size="large"
                variant="brand"
                shaded
                disabled={!dob}
            >
                <ButtonLabel>
                    Next
                </ButtonLabel>
                <ArrowNarrowRight />
            </StyledButton>
        </>
    );
};

export default AskDateOfBirth;
