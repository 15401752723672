import useSticky from 'hooks/useSticky';
import { DateSplitterContainer, DateSplitterLabel } from './styled';

interface Params {
    date?: string;
}

const DateSplitter = ({ date }: Params) => {
    const container = useSticky<HTMLDivElement>();
    return (
        <DateSplitterContainer ref={container.ref} isSticky={container.isSticky}>
            <DateSplitterLabel>{date}</DateSplitterLabel>
        </DateSplitterContainer>
    );
};

export default DateSplitter;
